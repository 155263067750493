@import '/styles/vars';

html {
    .container {
        padding: 4.484vw 0 5.415vw 0;
        user-select: none;
    }
    .content {
        background: $color_red;
        padding: 12px 0 12px 0;
        z-index: 2;
        position: relative;
        width: 100vw;
        width: var(--vw100);
        left: 50%;
        position: relative;
        margin: 0 0 0 -50vw;
        color: #fff;
        h2 {
            color: #fff;
        }
        @include mobile {
            padding: 36px 0;
        }
    }
    .center {
        margin-top: 48px;
        margin-bottom: 48px;
        border-top: 1px dashed rgba(255,255,255,.2);
        padding: 24px 0;
        display: flex;
        border-bottom: 1px dashed rgba(255,255,255,.2);
        span {
            font-size: 130%;
            font-weight: 500;
            margin-left: 0.3em;
        }
        a {
            color: #fff;
            text-decoration: underline;
            margin-left: 0.3em;
            &:hover {
                text-decoration: none;
            }
        }
        > div {
            width: 50%;
            flex-shrink: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .main {
            width: 40%;
            flex-shrink: 0;
            > div {
                position: relative;
                > div {
                    overflow: hidden;
                    position: relative;
                    &:last-child:not(:first-child) {
                        position: absolute;
                        top: 0;
                    }
                }
            }
            svg {
                width: 54px;
                height: 54px;
                color: $color_yellow;
                margin: 0 4px;
                fill: $color_yellow;
            }
            .darker {
                svg {
                    fill: #a94345 !important;
                    color: #a94345 !important;
                }
            }
        }
        @include mobile {
            flex-direction: column;
            border-top: 0;
            margin-top: 0;
            margin-bottom: 24px;
            .main {
                svg {
                    width: 36px;
                    height: 36px;
                }
            }
            > div {
                width: 100% !important;
                &:first-child, &:last-child {
                    order: 1;
                }
            }
        }
    }
    .review {
        font-size: 130%;
        font-style: italic;
        max-width: 720px;
        margin: 0 auto 24px auto;
        position: relative;
        padding: 0 24px;
        svg {
            position: absolute;
            top: 0;
            width: 48px;
            height: 48px;
            opacity: 0.4;
            &:first-child {
                right: 100%;
                transform: rotatey(180deg);
            }
            &:last-child {
                left: 100%;
            }
        }
        @include mobile {
            font-size: 100%;
            padding: 0 12px;
            margin: 0 32px 12px 32px;
            + div {
                opacity: 0.6;
            }
            svg {
                top: 4px;
                width: 24px;
                height: 24px;
            }
        }
    }
    .wrap {
        width: 100%;
        max-width: $wrap;
        margin: 0 auto;
        padding: 24px 24px;
        text-align: center;
        p {
            margin-top: 12px;
            margin-bottom: 24px;
        }
        @include mobile {
            padding: 0 12px 0px 12px;
            flex-direction: column;
            h2 {
                span {
                    display: block;
                }
            }
        }
    }
    .top {
        width: 100%;
        position: absolute;
        bottom: 100%;
        height: 4.484vw;
        margin-bottom: -2px;
        fill: $color_red;
    }
    .bottom {
        fill: $color_red;
        width: 100%;
        position: absolute;
        top: 100%;
        height: 5.415vw;
        margin-top: -6px;
    }
}
